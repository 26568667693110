@import "../../style/themes/default";
@import "../../style/mixins/index";

@spin-prefix-cls: ~"@{ant-prefix}-spin";
@spin-dot-default: @text-color-secondary;

.@{spin-prefix-cls} {
  .reset-component;
  color: @primary-color;
  vertical-align: middle;
  text-align: center;
  opacity: 0;
  position: absolute;
  transition: transform 0.3s @ease-in-out-circ;
  display: none;

  &-spinning {
    opacity: 1;
    position: static;
    display: inline-block;
  }

  &-nested-loading {
    position: relative;
    > div > .@{spin-prefix-cls} {
      position: absolute;
      height: 100%;
      max-height: 320px;
      width: 100%;
      z-index: 4;
      .@{spin-prefix-cls}-dot {
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -@spin-dot-size / 2;
      }
      .@{spin-prefix-cls}-text {
        position: absolute;
        top: 50%;
        width: 100%;
        padding-top: (@spin-dot-size - @font-size-base) / 2 + 2px;
        text-shadow: 0 1px 2px #fff;
      }
      &.@{spin-prefix-cls}-show-text .@{spin-prefix-cls}-dot {
        margin-top: -@spin-dot-size / 2 - 10px;
      }
    }

    > div > .@{spin-prefix-cls}-sm {
      .@{spin-prefix-cls}-dot {
        margin: -@spin-dot-size-sm / 2;
      }
      .@{spin-prefix-cls}-text {
        padding-top: (@spin-dot-size-sm - @font-size-base) / 2 + 2px;
      }
      &.@{spin-prefix-cls}-show-text .@{spin-prefix-cls}-dot {
        margin-top: -@spin-dot-size-sm / 2 - 10px;
      }
    }

    > div > .@{spin-prefix-cls}-lg {
      .@{spin-prefix-cls}-dot {
        margin: -@spin-dot-size-lg / 2;
      }
      .@{spin-prefix-cls}-text {
        padding-top: (@spin-dot-size-lg - @font-size-base) / 2 + 2px;
      }
      &.@{spin-prefix-cls}-show-text .@{spin-prefix-cls}-dot {
        margin-top: -@spin-dot-size-lg / 2 - 10px;
      }
    }
  }

  &-container {
    position: relative;
    .clearfix;
  }

  &-blur {
    pointer-events: none;
    user-select: none;
    overflow: hidden;
    opacity: 0.7;
    -webkit-filter: blur(0.5px);
    filter: blur(0.5px);

    /* autoprefixer: off */
    filter: ~"progid\:DXImageTransform\.Microsoft\.Blur(PixelRadius\=1, MakeShadow\=false)";
    // workround for a strange style bug in safari:
    // https://github.com/ant-design/ant-design/issues/4622
    // have no clue why this works
    -webkit-transform: translateZ(0);
    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: #fff;
      opacity: 0.3;
      transition: all .3s;
      z-index: 10;
    }
  }

  // tip
  // ------------------------------
  &-tip {
    color: @spin-dot-default;
  }

  // dots
  // ------------------------------

  &-dot {
    position: relative;
    display: inline-block;
    .square(@spin-dot-size);

    i {
      width: 9px;
      height: 9px;
      border-radius: 100%;
      background-color: @primary-color;
      transform: scale(0.75);
      display: block;
      position: absolute;
      opacity: 0.3;
      animation: antSpinMove 1s infinite linear alternate;
      transform-origin: 50% 50%;
      &:nth-child(1) {
        left: 0;
        top: 0;
      }
      &:nth-child(2) {
        right: 0;
        top: 0;
        animation-delay: 0.4s;
      }
      &:nth-child(3) {
        right: 0;
        bottom: 0;
        animation-delay: 0.8s;
      }
      &:nth-child(4) {
        left: 0;
        bottom: 0;
        animation-delay: 1.2s;
      }
    }

    &-spin {
      transform: rotate(45deg);
      animation: antRotate 1.2s infinite linear;
    }
  }

  // Sizes
  // ------------------------------
  // small
  &-sm &-dot {
    .square(@spin-dot-size-sm);
    i {
      width: 6px;
      height: 6px;
    }
  }

  // large
  &-lg &-dot {
    .square(@spin-dot-size-lg);
    i {
      width: 14px;
      height: 14px;
    }
  }

  &&-show-text &-text {
    display: block;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ */
  .@{spin-prefix-cls}-blur {
    background: @component-background;
    opacity: 0.5;
  }
}

@keyframes antSpinMove {
  to {
    opacity: 1;
  }
}

@keyframes antRotate {
  to {
    transform: rotate(405deg);
  }
}
