@import "../../style/themes/default";
@import "../../style/mixins/index";

@badge-prefix-cls: ~"@{ant-prefix}-badge";
@number-prefix-cls: ~"@{ant-prefix}-scroll-number";

.@{badge-prefix-cls} {
  .reset-component;
  position: relative;
  display: inline-block;
  line-height: 1;
  vertical-align: middle;

  &-count {
    position: absolute;
    transform: translateX(-50%);
    top: -@badge-height / 2;
    height: @badge-height;
    border-radius: @badge-height / 2;
    min-width: @badge-height;
    background: @highlight-color;
    color: #fff;
    line-height: @badge-height;
    text-align: center;
    padding: 0 6px;
    font-size: @badge-font-size;
    font-weight: @badge-font-weight;
    white-space: nowrap;
    transform-origin: -10% center;
    box-shadow: 0 0 0 1px #fff;
    a,
    a:hover {
      color: #fff;
    }
  }

  &-multiple-words {
    padding: 0 8px;
  }

  &-dot {
    position: absolute;
    transform: translateX(-50%);
    transform-origin: 0 center;
    top: -@badge-dot-size / 2;
    height: @badge-dot-size;
    width: @badge-dot-size;
    border-radius: 100%;
    background: @highlight-color;
    z-index: 10;
    box-shadow: 0 0 0 1px #fff;
  }

  &-status {
    line-height: inherit;
    vertical-align: baseline;

    &-dot {
      width: @badge-status-size;
      height: @badge-status-size;
      display: inline-block;
      border-radius: 50%;
      vertical-align: middle;
      position: relative;
      top: -1px;
    }
    &-success {
      background-color: @success-color;
    }
    &-processing {
      background-color: @processing-color;
      position: relative;
      &:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        border: 1px solid @processing-color;
        content: '';
        animation: antStatusProcessing 1.2s infinite ease-in-out;
      }
    }
    &-default {
      background-color: @normal-color;
    }
    &-error {
      background-color: @error-color;
    }
    &-warning {
      background-color: @warning-color;
    }
    &-text {
      color: @text-color;
      font-size: @font-size-base;
      margin-left: 8px;
    }
  }

  &-zoom-appear,
  &-zoom-enter {
    animation: antZoomBadgeIn .3s @ease-out-back;
    animation-fill-mode: both;
  }

  &-zoom-leave {
    animation: antZoomBadgeOut .3s @ease-in-back;
    animation-fill-mode: both;
  }

  &-not-a-wrapper .@{ant-prefix}-scroll-number {
    top: auto;
    display: block;
    position: relative;
  }

  &-not-a-wrapper .@{badge-prefix-cls}-count {
    transform: none;
  }
}

@keyframes antStatusProcessing {
  0% {
    transform: scale(0.8);
    opacity: 0.5;
  }
  100% {
    transform: scale(2.4);
    opacity: 0;
  }
}

.@{number-prefix-cls} {
  overflow: hidden;
  &-only {
    display: inline-block;
    transition: all .3s @ease-in-out;
    height: @badge-height;
    > p {
      height: @badge-height;
      margin: 0;
    }
  }
}

@keyframes antZoomBadgeIn {
  0% {
    opacity: 0;
    transform: scale(0) translateX(-50%);
  }
  100% {
    transform: scale(1) translateX(-50%);
  }
}

@keyframes antZoomBadgeOut {
  0% {
    transform: scale(1) translateX(-50%);
  }
  100% {
    opacity: 0;
    transform: scale(0) translateX(-50%);
  }
}
