@import "../node_modules/antd/dist/antd.less"; // 引入官方提供的 less 样式入口文件
@import "./theme.less";

.ant-dropdown-menu {
  padding: 0;
  box-shadow: none;
}

.modal-form {
  .modal-form-footer {
    width: 94%;
    text-align: right;
    .ant-btn + .ant-btn {
      margin-left: 10px;
    }
  }
  .ant-form-item {
    display: flex;
    margin: 10px 0;
    & > .ant-form-item-label {
      width: 27.333%;
      & + .ant-form-item-control-wrapper {
        width: 66.666%;
      }
    }
  }
  .ant-form-item-required:before {
    display: none;
  }
  .ant-form-item-label label:after {
    content: " ";
  }
  .ant-alert {
    margin-bottom: 24px;
  }
  .ant-form-item-children {
    color: #8c8c8c;
    font-size: 14px;
  }
  .ant-form-item-label label {
    color: #595959;
  }
  .ant-form-item-label label:after {
    margin-right: 16px;
  }
  .ant-select-disabled {
    .ant-select-arrow {
      display: none;
    }
  }
  .ant-input-number {
    width: 100%;
  }
  .ant-calendar-picker {
    width: 100%;
  }
}

.ant-input-number-handler-wrap {
  display: none;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  box-shadow: none;
}

.ant-menu-dark.ant-menu-inline .ant-menu-item {
  margin: 0;
}

.ant-table-tbody > tr {
  &:nth-child(2n + 1) {
    background: #fff;
  }
  &:nth-child(2n) {
    background: #fcfcfc;
  }
}

.list {
  .money {
    // text-align: right;
    // display: inline-block;
    // width: 100%;
  }
  .amount {
    // text-align: right;
    // display: inline-block;
    // width: 100%;
  }
}

@media print {
  @page {
    size: auto; /* auto is the initial value */
    /* this affects the margin in the printer settings */
    margin: 20px 0 0;
    size: landscape;
  }
  .header,
  .ant-layout-sider {
    display: none;
  }
  .Report-title {
    .ant-btn {
      display: none;
    }
  }
  .ChargeReportDetail-MonthlyDetail{
    &>*{
      display: none;
    }
    
  }
  .Report-remark{
    display: none;
  }
  .ant-card-bordered{
    border: 0 none;
  }
}
.ant-calendar-header .ant-calendar-next-century-btn::after, .ant-calendar-header .ant-calendar-next-decade-btn::after, .ant-calendar-header .ant-calendar-next-year-btn::after{
  content: '\00bb'
}
.ant-calendar-header .ant-calendar-next-month-btn:after{
  content: '\203a';
}
.ant-calendar-header .ant-calendar-prev-month-btn:after{
  content: '\2039';
}
.ant-calendar-header .ant-calendar-prev-century-btn:after, .ant-calendar-header .ant-calendar-prev-decade-btn:after, .ant-calendar-header .ant-calendar-prev-year-btn:after{
  content: '\00ab';
}


.Balance-error{
  color:#f04864;
}

.page-content + .page-content{
  display: none;
}

