@keyframes loadingCircle {
  0% {
    transform-origin: 50% 50%;
    transform: rotate(0deg);
  }
  100% {
    transform-origin: 50% 50%;
    transform: rotate(360deg);
  }
}
