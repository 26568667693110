@import "../../style/themes/default";
@import "../../style/mixins/index";

@breadcrumb-prefix-cls: ~"@{ant-prefix}-breadcrumb";

.@{breadcrumb-prefix-cls} {
  .reset-component;
  color: @breadcrumb-base-color;
  font-size: @breadcrumb-font-size;

  .@{iconfont-css-prefix} {
    font-size: @breadcrumb-icon-font-size;
  }

  a {
    color: @breadcrumb-link-color;
    transition: color .3s;
    &:hover {
      color: @breadcrumb-link-color-hover;
    }
  }

  & > span:last-child {
    color: @breadcrumb-last-item-color;
  }

  & > span:last-child &-separator {
    display: none;
  }

  &-separator {
    margin: @breadcrumb-separator-margin;
    color: @breadcrumb-separator-color;
  }

  &-link {
    > .@{iconfont-css-prefix} + span {
      margin-left: 4px;
    }
  }
}
