@import '../themes/default';
@import "../mixins/iconfont";

// font-face
@font-face {
  font-family: 'anticon';
  src: url('@{icon-url}.eot'); /* IE9*/
  src:
  /* chrome、firefox */
  url('@{icon-url}.woff') format('woff'),
  /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
  url('@{icon-url}.ttf') format('truetype'),
  /* iOS 4.1- */
  url('@{icon-url}.svg#iconfont') format('svg');
}

.@{iconfont-css-prefix} {
 .iconfont-mixin();
}

.@{iconfont-css-prefix}-step-forward:before { content: "\e600"; }
.@{iconfont-css-prefix}-step-backward:before { content: "\e601"; }
.@{iconfont-css-prefix}-forward:before { content: "\e602"; }
.@{iconfont-css-prefix}-backward:before { content: "\e603"; }
.@{iconfont-css-prefix}-caret-right:before { content: "\e604"; }
.@{iconfont-css-prefix}-caret-left:before { content: "\e605"; }
.@{iconfont-css-prefix}-caret-down:before { content: "\e606"; }
.@{iconfont-css-prefix}-caret-up:before { content: "\e607"; }
.@{iconfont-css-prefix}-right-circle:before { content: "\e608"; }
.@{iconfont-css-prefix}-circle-right:before { content: "\e608"; } // antd@1.x compatibility alias: right-circle
.@{iconfont-css-prefix}-caret-circle-right:before { content: "\e608"; } // antd@1.x compatibility alias: right-circle
.@{iconfont-css-prefix}-left-circle:before { content: "\e609"; }
.@{iconfont-css-prefix}-circle-left:before { content: "\e609"; } // antd@1.x compatibility alias: left-circle
.@{iconfont-css-prefix}-caret-circle-left:before { content: "\e609"; } // antd@1.x compatibility alias: left-circle
.@{iconfont-css-prefix}-up-circle:before { content: "\e60a"; }
.@{iconfont-css-prefix}-circle-up:before { content: "\e60a"; } // antd@1.x compatibility alias: up-circle
.@{iconfont-css-prefix}-caret-circle-up:before { content: "\e60a"; } // antd@1.x compatibility alias: up-circle
.@{iconfont-css-prefix}-down-circle:before { content: "\e60b"; }
.@{iconfont-css-prefix}-circle-down:before { content: "\e60b"; } // antd@1.x compatibility alias: down-circle
.@{iconfont-css-prefix}-caret-circle-down:before { content: "\e60b"; } // antd@1.x compatibility alias: down-circle
.@{iconfont-css-prefix}-right-circle-o:before { content: "\e60c"; }
.@{iconfont-css-prefix}-circle-o-right:before { content: "\e60c"; } // antd@1.x compatibility alias: right-circle-o
.@{iconfont-css-prefix}-caret-circle-o-right:before { content: "\e60c"; } // antd@1.x compatibility alias: right-circle-o
.@{iconfont-css-prefix}-left-circle-o:before { content: "\e60d"; }
.@{iconfont-css-prefix}-circle-o-left:before { content: "\e60d"; } // antd@1.x compatibility alias: left-circle-o
.@{iconfont-css-prefix}-caret-circle-o-left:before { content: "\e60d"; } // antd@1.x compatibility alias: left-circle-o
.@{iconfont-css-prefix}-up-circle-o:before { content: "\e60e"; }
.@{iconfont-css-prefix}-circle-o-up:before { content: "\e60e"; } // antd@1.x compatibility alias: up-circle-o
.@{iconfont-css-prefix}-caret-circle-o-up:before { content: "\e60e"; } // antd@1.x compatibility alias: up-circle-o
.@{iconfont-css-prefix}-down-circle-o:before { content: "\e60f"; }
.@{iconfont-css-prefix}-circle-o-down:before { content: "\e60f"; } // antd@1.x compatibility alias: down-circle-o
.@{iconfont-css-prefix}-caret-circle-o-down:before { content: "\e60f"; } // antd@1.x compatibility alias: down-circle-o
.@{iconfont-css-prefix}-verticle-left:before { content: "\e610"; }
.@{iconfont-css-prefix}-verticle-right:before { content: "\e611"; }
.@{iconfont-css-prefix}-rollback:before { content: "\e612"; }
.@{iconfont-css-prefix}-retweet:before { content: "\e613"; }
.@{iconfont-css-prefix}-shrink:before { content: "\e614"; }
.@{iconfont-css-prefix}-arrows-alt:before { content: "\e615"; }
.@{iconfont-css-prefix}-arrow-salt:before { content: "\e615"; } // antd@1.x compatibility alias: arrows-alt
.@{iconfont-css-prefix}-reload:before { content: "\e616"; }
.@{iconfont-css-prefix}-double-right:before { content: "\e617"; }
.@{iconfont-css-prefix}-double-left:before { content: "\e618"; }
.@{iconfont-css-prefix}-arrow-down:before { content: "\e619"; }
.@{iconfont-css-prefix}-arrow-up:before { content: "\e61a"; }
.@{iconfont-css-prefix}-arrow-right:before { content: "\e61b"; }
.@{iconfont-css-prefix}-arrow-left:before { content: "\e61c"; }
.@{iconfont-css-prefix}-down:before { content: "\e61d"; }
.@{iconfont-css-prefix}-up:before { content: "\e61e"; }
.@{iconfont-css-prefix}-right:before { content: "\e61f"; }
.@{iconfont-css-prefix}-left:before { content: "\e620"; }
.@{iconfont-css-prefix}-minus-square-o:before { content: "\e621"; }
.@{iconfont-css-prefix}-minus-circle:before { content: "\e622"; }
.@{iconfont-css-prefix}-minus-circle-o:before { content: "\e623"; }
.@{iconfont-css-prefix}-minus:before { content: "\e624"; }
.@{iconfont-css-prefix}-plus-circle-o:before { content: "\e625"; }
.@{iconfont-css-prefix}-plus-circle:before { content: "\e626"; }
.@{iconfont-css-prefix}-plus:before { content: "\e627"; }
.@{iconfont-css-prefix}-info-circle:before { content: "\e628"; }
.@{iconfont-css-prefix}-info-circle-o:before { content: "\e629"; }
.@{iconfont-css-prefix}-info:before { content: "\e62a"; }
.@{iconfont-css-prefix}-exclamation:before { content: "\e62b"; }
.@{iconfont-css-prefix}-exclamation-circle:before { content: "\e62c"; }
.@{iconfont-css-prefix}-exclamation-circle-o:before { content: "\e62d"; }
.@{iconfont-css-prefix}-close-circle:before { content: "\e62e"; }
.@{iconfont-css-prefix}-cross-circle:before { content: "\e62e"; } // antd@1.x compatibility alias: close-circle
.@{iconfont-css-prefix}-close-circle-o:before { content: "\e62f"; }
.@{iconfont-css-prefix}-cross-circle-o:before { content: "\e62f"; } // antd@1.x compatibility alias: close-circle-o
.@{iconfont-css-prefix}-check-circle:before { content: "\e630"; }
.@{iconfont-css-prefix}-check-circle-o:before { content: "\e631"; }
.@{iconfont-css-prefix}-check:before { content: "\e632"; }
.@{iconfont-css-prefix}-close:before { content: "\e633"; }
.@{iconfont-css-prefix}-cross:before { content: "\e633"; } // antd@1.x compatibility alias: close
.@{iconfont-css-prefix}-customer-service:before { content: "\e634"; }
.@{iconfont-css-prefix}-customerservice:before { content: "\e634"; } // antd@1.x compatibility alias: customer-service
.@{iconfont-css-prefix}-credit-card:before { content: "\e635"; }
.@{iconfont-css-prefix}-code-o:before { content: "\e636"; }
.@{iconfont-css-prefix}-book:before { content: "\e637"; }
.@{iconfont-css-prefix}-bars:before { content: "\e639"; }
.@{iconfont-css-prefix}-question:before { content: "\e63a"; }
.@{iconfont-css-prefix}-question-circle:before { content: "\e63b"; }
.@{iconfont-css-prefix}-question-circle-o:before { content: "\e63c"; }
.@{iconfont-css-prefix}-pause:before { content: "\e63d"; }
.@{iconfont-css-prefix}-pause-circle:before { content: "\e63e"; }
.@{iconfont-css-prefix}-pause-circle-o:before { content: "\e63f"; }
.@{iconfont-css-prefix}-clock-circle:before { content: "\e640"; }
.@{iconfont-css-prefix}-clock-circle-o:before { content: "\e641"; }
.@{iconfont-css-prefix}-swap:before { content: "\e642"; }
.@{iconfont-css-prefix}-swap-left:before { content: "\e643"; }
.@{iconfont-css-prefix}-swap-right:before { content: "\e644"; }
.@{iconfont-css-prefix}-plus-square-o:before { content: "\e645"; }
.@{iconfont-css-prefix}-frown:before { content: "\e646"; }
.@{iconfont-css-prefix}-frown-circle:before { content: "\e646"; } // antd@1.x compatibility alias: frown
.@{iconfont-css-prefix}-ellipsis:before { content: "\e647"; }
.@{iconfont-css-prefix}-copy:before { content: "\e648"; }
.@{iconfont-css-prefix}-menu-fold:before { content: "\e9ac"; }
.@{iconfont-css-prefix}-mail:before { content: "\e659"; }
.@{iconfont-css-prefix}-logout:before { content: "\e65a"; }
.@{iconfont-css-prefix}-link:before { content: "\e65b"; }
.@{iconfont-css-prefix}-area-chart:before { content: "\e65c"; }
.@{iconfont-css-prefix}-line-chart:before { content: "\e65d"; }
.@{iconfont-css-prefix}-home:before { content: "\e65e"; }
.@{iconfont-css-prefix}-laptop:before { content: "\e65f"; }
.@{iconfont-css-prefix}-star:before { content: "\e660"; }
.@{iconfont-css-prefix}-star-o:before { content: "\e661"; }
.@{iconfont-css-prefix}-folder:before { content: "\e662"; }
.@{iconfont-css-prefix}-filter:before { content: "\e663"; }
.@{iconfont-css-prefix}-file:before { content: "\e664"; }
.@{iconfont-css-prefix}-exception:before { content: "\e665"; }
.@{iconfont-css-prefix}-meh:before { content: "\e666"; }
.@{iconfont-css-prefix}-meh-circle:before { content: "\e666"; } // antd@1.x compatibility alias: meh
.@{iconfont-css-prefix}-meh-o:before { content: "\e667"; }
.@{iconfont-css-prefix}-shopping-cart:before { content: "\e668"; }
.@{iconfont-css-prefix}-save:before { content: "\e669"; }
.@{iconfont-css-prefix}-user:before { content: "\e66a"; }
.@{iconfont-css-prefix}-video-camera:before { content: "\e66b"; }
.@{iconfont-css-prefix}-to-top:before { content: "\e66c"; }
.@{iconfont-css-prefix}-team:before { content: "\e66d"; }
.@{iconfont-css-prefix}-tablet:before { content: "\e66e"; }
.@{iconfont-css-prefix}-solution:before { content: "\e66f"; }
.@{iconfont-css-prefix}-search:before { content: "\e670"; }
.@{iconfont-css-prefix}-share-alt:before { content: "\e671"; }
.@{iconfont-css-prefix}-setting:before { content: "\e672"; }
.@{iconfont-css-prefix}-poweroff:before { content: "\e6d5"; }
.@{iconfont-css-prefix}-picture:before { content: "\e674"; }
.@{iconfont-css-prefix}-phone:before { content: "\e675"; }
.@{iconfont-css-prefix}-paper-clip:before { content: "\e676"; }
.@{iconfont-css-prefix}-notification:before { content: "\e677"; }
.@{iconfont-css-prefix}-mobile:before { content: "\e678"; }
.@{iconfont-css-prefix}-menu-unfold:before { content: "\e9ad"; }
.@{iconfont-css-prefix}-inbox:before { content: "\e67a"; }
.@{iconfont-css-prefix}-lock:before { content: "\e67b"; }
.@{iconfont-css-prefix}-qrcode:before { content: "\e67c"; }
.@{iconfont-css-prefix}-play-circle:before { content: "\e6d0"; }
.@{iconfont-css-prefix}-play-circle-o:before { content: "\e6d1"; }
.@{iconfont-css-prefix}-tag:before { content: "\e6d2"; }
.@{iconfont-css-prefix}-tag-o:before { content: "\e6d3"; }
.@{iconfont-css-prefix}-tags:before { content: "\e67d"; }
.@{iconfont-css-prefix}-tags-o:before { content: "\e67e"; }
.@{iconfont-css-prefix}-cloud-o:before { content: "\e67f"; }
.@{iconfont-css-prefix}-cloud:before { content: "\e680"; }
.@{iconfont-css-prefix}-cloud-upload:before { content: "\e681"; }
.@{iconfont-css-prefix}-cloud-download:before { content: "\e682"; }
.@{iconfont-css-prefix}-cloud-download-o:before { content: "\e683"; }
.@{iconfont-css-prefix}-cloud-upload-o:before { content: "\e684"; }
.@{iconfont-css-prefix}-environment:before { content: "\e685"; }
.@{iconfont-css-prefix}-environment-o:before { content: "\e686"; }
.@{iconfont-css-prefix}-eye:before { content: "\e687"; }
.@{iconfont-css-prefix}-eye-o:before { content: "\e688"; }
.@{iconfont-css-prefix}-camera:before { content: "\e689"; }
.@{iconfont-css-prefix}-camera-o:before { content: "\e68a"; }
.@{iconfont-css-prefix}-windows:before { content: "\e68b"; }
.@{iconfont-css-prefix}-apple:before { content: "\e68c"; }
.@{iconfont-css-prefix}-apple-o:before { content: "\e6d4"; }
.@{iconfont-css-prefix}-android:before { content: "\e938"; }
.@{iconfont-css-prefix}-android-o:before { content: "\e68d"; }
.@{iconfont-css-prefix}-aliwangwang:before { content: "\e68e"; }
.@{iconfont-css-prefix}-aliwangwang-o:before { content: "\e68f"; }
.@{iconfont-css-prefix}-export:before { content: "\e691"; }
.@{iconfont-css-prefix}-edit:before { content: "\e692"; }
.@{iconfont-css-prefix}-appstore-o:before { content: "\e695"; }
.@{iconfont-css-prefix}-appstore:before { content: "\e696"; }
.@{iconfont-css-prefix}-scan:before { content: "\e697"; }
.@{iconfont-css-prefix}-file-text:before { content: "\e698"; }
.@{iconfont-css-prefix}-folder-open:before { content: "\e699"; }
.@{iconfont-css-prefix}-hdd:before { content: "\e69a"; }
.@{iconfont-css-prefix}-ie:before { content: "\e69b"; }
.@{iconfont-css-prefix}-file-jpg:before { content: "\e69c"; }
.@{iconfont-css-prefix}-like:before { content: "\e64c"; }
.@{iconfont-css-prefix}-like-o:before { content: "\e69d"; }
.@{iconfont-css-prefix}-dislike:before { content: "\e64b"; }
.@{iconfont-css-prefix}-dislike-o:before { content: "\e69e"; }
.@{iconfont-css-prefix}-delete:before { content: "\e69f"; }
.@{iconfont-css-prefix}-enter:before { content: "\e6a0"; }
.@{iconfont-css-prefix}-pushpin-o:before { content: "\e6a1"; }
.@{iconfont-css-prefix}-pushpin:before { content: "\e6a2"; }
.@{iconfont-css-prefix}-heart:before { content: "\e6a3"; }
.@{iconfont-css-prefix}-heart-o:before { content: "\e6a4"; }
.@{iconfont-css-prefix}-pay-circle:before { content: "\e6a5"; }
.@{iconfont-css-prefix}-pay-circle-o:before { content: "\e6a6"; }
.@{iconfont-css-prefix}-smile:before { content: "\e6a7"; }
.@{iconfont-css-prefix}-smile-circle:before { content: "\e6a7"; }  // antd@1.x compatibility alias: smile
.@{iconfont-css-prefix}-smile-o:before { content: "\e6a8"; }
.@{iconfont-css-prefix}-frown-o:before { content: "\e6a9"; }
.@{iconfont-css-prefix}-calculator:before { content: "\e6aa"; }
.@{iconfont-css-prefix}-message:before { content: "\e6ab"; }
.@{iconfont-css-prefix}-chrome:before { content: "\e6ac"; }
.@{iconfont-css-prefix}-github:before { content: "\e6ad"; }
.@{iconfont-css-prefix}-file-unknown:before { content: "\e6af"; }
.@{iconfont-css-prefix}-file-excel:before { content: "\e6b0"; }
.@{iconfont-css-prefix}-file-ppt:before { content: "\e6b1"; }
.@{iconfont-css-prefix}-file-word:before { content: "\e6b2"; }
.@{iconfont-css-prefix}-file-pdf:before { content: "\e6b3"; }
.@{iconfont-css-prefix}-desktop:before { content: "\e6b4"; }
.@{iconfont-css-prefix}-upload:before { content: "\e6b6"; }
.@{iconfont-css-prefix}-download:before { content: "\e6b7"; }
.@{iconfont-css-prefix}-pie-chart:before { content: "\e6b8"; }
.@{iconfont-css-prefix}-unlock:before { content: "\e6ba"; }
.@{iconfont-css-prefix}-calendar:before { content: "\e6bb"; }
.@{iconfont-css-prefix}-windows-o:before { content: "\e6bc"; }
.@{iconfont-css-prefix}-dot-chart:before { content: "\e6bd"; }
.@{iconfont-css-prefix}-bar-chart:before { content: "\e6be"; }
.@{iconfont-css-prefix}-code:before { content: "\e6bf"; }
.@{iconfont-css-prefix}-api:before { content: "\e951"; }
.@{iconfont-css-prefix}-plus-square:before { content: "\e6c0"; }
.@{iconfont-css-prefix}-minus-square:before { content: "\e6c1"; }
.@{iconfont-css-prefix}-close-square:before { content: "\e6c2"; }
.@{iconfont-css-prefix}-close-square-o:before { content: "\e6c3"; }
.@{iconfont-css-prefix}-check-square:before { content: "\e6c4"; }
.@{iconfont-css-prefix}-check-square-o:before { content: "\e6c5"; }
.@{iconfont-css-prefix}-fast-backward:before { content: "\e6c6"; }
.@{iconfont-css-prefix}-fast-forward:before { content: "\e6c7"; }
.@{iconfont-css-prefix}-up-square:before { content: "\e6c8"; }
.@{iconfont-css-prefix}-down-square:before { content: "\e6c9"; }
.@{iconfont-css-prefix}-left-square:before { content: "\e6ca"; }
.@{iconfont-css-prefix}-right-square:before { content: "\e6cb"; }
.@{iconfont-css-prefix}-right-square-o:before { content: "\e6cc"; }
.@{iconfont-css-prefix}-left-square-o:before { content: "\e6cd"; }
.@{iconfont-css-prefix}-down-square-o:before { content: "\e6ce"; }
.@{iconfont-css-prefix}-up-square-o:before { content: "\e6cf"; }
.@{iconfont-css-prefix}-loading:before { content: "\e64d"; }
.@{iconfont-css-prefix}-loading-3-quarters:before { content: "\e6ae"; }
.@{iconfont-css-prefix}-bulb:before { content: "\e649"; }
.@{iconfont-css-prefix}-select:before { content: "\e64a"; }
.@{iconfont-css-prefix}-addfile:before,
.@{iconfont-css-prefix}-file-add:before { content: "\e910"; }
.@{iconfont-css-prefix}-addfolder:before,
.@{iconfont-css-prefix}-folder-add:before { content: "\e914"; }
.@{iconfont-css-prefix}-switcher:before { content: "\e913"; }
.@{iconfont-css-prefix}-rocket:before { content: "\e90f"; }
.@{iconfont-css-prefix}-dingding:before { content: "\e923"; }
.@{iconfont-css-prefix}-dingding-o:before { content: "\e925"; }
.@{iconfont-css-prefix}-bell:before { content: "\e64e"; }
.@{iconfont-css-prefix}-disconnect:before { content: "\e64f"; }
.@{iconfont-css-prefix}-database:before { content: "\e650"; }
.@{iconfont-css-prefix}-compass:before { content: "\e6db"; }
.@{iconfont-css-prefix}-barcode:before { content: "\e652"; }
.@{iconfont-css-prefix}-hourglass:before { content: "\e653"; }
.@{iconfont-css-prefix}-key:before { content: "\e654"; }
.@{iconfont-css-prefix}-flag:before { content: "\e655"; }
.@{iconfont-css-prefix}-layout:before { content: "\e656"; }
.@{iconfont-css-prefix}-login:before { content: "\e657"; }
.@{iconfont-css-prefix}-printer:before { content: "\e673"; }
.@{iconfont-css-prefix}-sound:before { content: "\e6e9"; }
.@{iconfont-css-prefix}-usb:before { content: "\e6d7"; }
.@{iconfont-css-prefix}-skin:before { content: "\e6d8"; }
.@{iconfont-css-prefix}-tool:before { content: "\e6d9"; }
.@{iconfont-css-prefix}-sync:before { content: "\e6da"; }
.@{iconfont-css-prefix}-wifi:before { content: "\e6d6"; }
.@{iconfont-css-prefix}-car:before { content: "\e6dc"; }
.@{iconfont-css-prefix}-copyright:before { content: "\e6de"; }
.@{iconfont-css-prefix}-schedule:before { content: "\e6df"; }
.@{iconfont-css-prefix}-user-add:before { content: "\e6ed"; }
.@{iconfont-css-prefix}-user-delete:before { content: "\e6e0"; }
.@{iconfont-css-prefix}-usergroup-add:before { content: "\e6dd"; }
.@{iconfont-css-prefix}-usergroup-delete:before { content: "\e6e1"; }
.@{iconfont-css-prefix}-man:before { content: "\e6e2"; }
.@{iconfont-css-prefix}-woman:before { content: "\e6ec"; }
.@{iconfont-css-prefix}-shop:before { content: "\e6e3"; }
.@{iconfont-css-prefix}-gift:before { content: "\e6e4"; }
.@{iconfont-css-prefix}-idcard:before { content: "\e6e5"; }
.@{iconfont-css-prefix}-medicine-box:before { content: "\e6e6"; }
.@{iconfont-css-prefix}-red-envelope:before { content: "\e6e7"; }
.@{iconfont-css-prefix}-coffee:before { content: "\e6e8"; }
.@{iconfont-css-prefix}-trademark:before { content: "\e651"; }
.@{iconfont-css-prefix}-safety:before { content: "\e6ea"; }
.@{iconfont-css-prefix}-wallet:before { content: "\e6eb"; }
.@{iconfont-css-prefix}-bank:before { content: "\e6ee"; }
.@{iconfont-css-prefix}-trophy:before { content: "\e6ef"; }
.@{iconfont-css-prefix}-contacts:before { content: "\e6f0"; }
.@{iconfont-css-prefix}-global:before { content: "\e6f1"; }
.@{iconfont-css-prefix}-shake:before { content: "\e94f"; }
.@{iconfont-css-prefix}-fork:before { content: "\e6f2"; }
.@{iconfont-css-prefix}-dashboard:before { content: "\e99a"; }
.@{iconfont-css-prefix}-profile:before { content: "\e999"; }
.@{iconfont-css-prefix}-table:before { content: "\e998"; }
.@{iconfont-css-prefix}-warning:before { content: "\e997"; }
.@{iconfont-css-prefix}-form:before { content: "\e996"; }
.@{iconfont-css-prefix}-spin:before {
  display: inline-block;
  animation: loadingCircle 1s infinite linear;
}

.@{iconfont-css-prefix}-weibo-square:before { content: "\e6f5"; }
.@{iconfont-css-prefix}-weibo-circle:before { content: "\e6f4"; }
.@{iconfont-css-prefix}-taobao-circle:before { content: "\e6f3"; }
.@{iconfont-css-prefix}-html5:before { content: "\e9c7"; }
.@{iconfont-css-prefix}-weibo:before { content: "\e9c6"; }
.@{iconfont-css-prefix}-twitter:before { content: "\e9c5"; }
.@{iconfont-css-prefix}-wechat:before { content: "\e9c4"; }
.@{iconfont-css-prefix}-youtube:before { content: "\e9c3"; }
.@{iconfont-css-prefix}-alipay-circle:before { content: "\e9c2"; }
.@{iconfont-css-prefix}-taobao:before { content: "\e9c1"; }
.@{iconfont-css-prefix}-skype:before { content: "\e9c0"; }
.@{iconfont-css-prefix}-qq:before { content: "\e9bf"; }
.@{iconfont-css-prefix}-medium-workmark:before { content: "\e9be"; }
.@{iconfont-css-prefix}-gitlab:before { content: "\e9bd"; }
.@{iconfont-css-prefix}-medium:before { content: "\e9bc"; }
.@{iconfont-css-prefix}-linkedin:before { content: "\e9bb"; }
.@{iconfont-css-prefix}-google-plus:before { content: "\e9ba"; }
.@{iconfont-css-prefix}-dropbox:before { content: "\e9b9"; }
.@{iconfont-css-prefix}-facebook:before { content: "\e9b8"; }
.@{iconfont-css-prefix}-codepen:before { content: "\e9b7"; }
.@{iconfont-css-prefix}-amazon:before { content: "\e9b6"; }
.@{iconfont-css-prefix}-google:before { content: "\e9b5"; }
.@{iconfont-css-prefix}-codepen-circle:before { content: "\e9b4"; }
.@{iconfont-css-prefix}-alipay:before { content: "\e9b3"; }
.@{iconfont-css-prefix}-ant-design:before { content: "\e9b2"; }
.@{iconfont-css-prefix}-aliyun:before { content: "\e9f4"; }
.@{iconfont-css-prefix}-zhihu:before { content: "\e703"; }
.@{iconfont-css-prefix}-file-markdown:before { content: "\e704"; }
.@{iconfont-css-prefix}-slack:before { content: "\e705"; }
.@{iconfont-css-prefix}-slack-square:before { content: "\e706"; }
.@{iconfont-css-prefix}-behance:before { content: "\e707"; }
.@{iconfont-css-prefix}-behance-square:before { content: "\e708"; }
.@{iconfont-css-prefix}-dribbble:before { content: "\e709"; }
.@{iconfont-css-prefix}-dribbble-square:before { content: "\e70a"; }
.@{iconfont-css-prefix}-instagram:before { content: "\e70b"; }
.@{iconfont-css-prefix}-yuque:before { content: "\e70c"; }
