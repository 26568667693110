.@{calendar-prefix-cls}-week-number {
  &-cell {
    opacity: 0.5;
  }
  .@{calendar-prefix-cls}-body tr {
    transition: all .3s;
    cursor: pointer;
    &:hover {
      background: @primary-1;
    }
    &.@{calendar-prefix-cls}-active-week {
      background: @primary-2;
      font-weight: bold;
    }
    .@{calendar-prefix-cls}-selected-day .@{calendar-prefix-cls}-date,
    .@{calendar-prefix-cls}-selected-day:hover .@{calendar-prefix-cls}-date {
      background: transparent;
      color: @text-color;
    }
  }
}
